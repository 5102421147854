& {
	// background: url('/images/body-bg.svg') fixed;
	background-image: url('/images/body-bg.png');
	background-attachment: fixed;
}

// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-wide {
		max-width: 1200px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

// headings
h4,
.h4 {
	text-transform: uppercase;
	font-family: $font-family-primary;
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;
	background: $white;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}
}

// image-collection
.image-collection {
	&.owl-carousel {
		.item {
			picture {
				width: 100%;
				height: 100%;

				img {
					height: 100%;
					object-fit: cover;
				}
			}

			.owl-caption-holder {
				z-index: 100;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

// default-card
.default-card {
	border-radius: 10px;
	border: none;
	box-shadow: 6px 3px 12px rgba($black, 0.15);

	.card-image {
		padding: 15px 15px 0 15px;

		@include media-breakpoint-up(xl) {
			padding: 20px 20px 0 20px;
		}

		.card-image-link {
			border-radius: 8px;
			overflow: hidden;
		}
	}

	.card-body {
		padding: 15px;

		@include media-breakpoint-up(xl) {
			padding: 20px;
		}

		.card-caption {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.card-title-link {
				margin-bottom: 0.5rem;
				color: $gray-darker;
			}

			.card-subtitle {
				order: -1;
				margin-top: 0;
				margin-bottom: 0.5rem;
				font-size: 16px;
			}

			.card-description {
				ul:not(.list) {
					@extend .custom-list-extra;
				}
			}
		}

		.card-buttons {
			margin-bottom: 15px;
		}
	}
}

// icons-collection
.icons-collection {
	.card {
		background: none;
		border: none;
		border-radius: 0;

		.card-image {
			margin-bottom: 5px;

			i {
				font-size: 50px;
			}
		}

		.card-body {
			padding: 0;

			.card-caption {
				.card-title {
					margin-bottom: 0;
					font-size: 16px;
					font-weight: 800;
					text-transform: uppercase;
					font-family: $font-family-primary;
				}

				.card-subtitle,
				.card-description {
					font-size: 16px;
					font-weight: 400;
				}

				.card-description {
					p {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

// tiles-collection
.tiles-collection {
	.card {
		border-radius: 10px;
		border: 10px solid #fff;
		border-bottom-width: 13px;
		box-shadow: 6px 3px 12px rgba($black, 0.15);

		.card-image {
			border-radius: 8px;
		}

		.card-img-overlay {
			top: auto;
			padding: 0;
			background: none;
			border-radius: 8px;

			.card-caption {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				margin-top: auto;
				padding: 20px;
				background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);
				text-shadow: none;

				.card-title {
					margin-bottom: 0;
				}

				.card-subtitle {
					order: -1;
					margin: 0;
					font-size: 16px;
				}

				.card-description {
					display: none;
				}
			}
		}
	}
}

// gallery
.gallery,
.gallery-horizontal {
	.gallery-item {
		.gallery-link {
			border-radius: 10px;
			border: 10px solid #fff;
			box-shadow: 6px 3px 12px rgba($black, 0.15);

			img,
			.gallery-caption {
				border-radius: 8px;
			}
		}
	}
}

// modal
&.modal-open {
	overflow: visible;
	padding-right: 0 !important;

	.modal {
		overflow: visible;
	}
}

.modal-backdrop {
	display: none;
}

.modal {
	top: auto;
	left: auto;
	right: 15px;
	bottom: 15px;
	width: auto;
	height: auto;
	padding-right: 0 !important;
	overflow: visible;

	@include media-breakpoint-up(xl) {
		right: 30px;
		bottom: 30px;
	}

	.modal-dialog {
		margin: 0 !important;
		max-width: 330px;

		.modal-content {
			border: none;
			border-radius: 8px;
			background: $white;
			box-shadow: 6px 6px 12px rgba($black, 0.15);

			.modal-header {
				display: block;
				padding: 30px 30px 0 30px;
				border-bottom: 0;
				text-align: center;

				.modal-title {
					color: $green-dark;
					font-size: 18px;
					text-transform: none;
					font-family: $font-family-secondary;
				}

				.close {
					z-index: 10;
					position: absolute;
					top: 0;
					right: 0;
					display: block;
					width: 35px;
					height: 35px;
					margin: 0;
					padding: 0;
					color: $gray-darker;
					font-size: 24px;
					font-family: sans-serif;
					opacity: 1;
					text-shadow: none;
					transition: 0.5s;

					&:hover {
						color: $cream;
					}
				}
			}

			.modal-body {
				padding: 0 30px 30px 30px;
				font-weight: 400;
				text-align: center;

				p {
					margin-bottom: 1rem;

					&:last-of-type {
						margin-bottom: 0;
					}
				}

				.btn {
					box-shadow: $shadow;
				}
			}
		}
	}
}
