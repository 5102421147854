// mini-sab
.mini-sab {
	.container {
		position: relative;
		display: flex;
		justify-content: center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: -75px 0 0 0;
			padding: 30px 15px;
			border-radius: 8px;
			background: $cream;
			box-shadow: 6px 6px 12px rgba($black, 0.15);

			@include media-breakpoint-up(lg) {
				margin-top: -40px;
				padding: 20px 10px;
			}
		}
	}
}
