// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green: #5d853d;
$green-dark: #3c5726;
$cream: #eed093;
$white: #fff;
$gray-dark: #3d3d3d;
$gray-darker: #2c343c;
$black: #000;

// theme-colors
$primary: $green;
$secondary: $green-dark;

// body
$body-bg: $white;
$body-color: $gray-dark;

// links
$link-color: $primary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Outfit:wght@300;400;700;800&display=swap');
$font-family-primary: 'Outfit', sans-serif;
$font-family-secondary: 'Alfa Slab One', serif;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px
$font-weight-base: 300;

$h1-font-size: $font-size-base * 2.125; // 34px
$h2-font-size: $font-size-base * 1.875; // 30px
$h3-font-size: $font-size-base * 1.5; // 24px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base * 1.125; // 18px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 400;
$headings-color: $gray-darker;

$paragraph-margin-bottom: 1.5rem;

// contextual
$light: $green;
$dark: $green-dark;
$text-light: $white;
$text-dark: $white;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

// carousel
$carousel-minheight: 0;
$carousel-padding: 0;
$carousel-minheight-large: 0;
$carousel-padding-large: 0;

/*
// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
