// footer
.footer {
	padding-top: 8vh;
	background: $green-dark;
	color: $white;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $white;
	}

	.h1,
	.h2 {
		color: $cream;
	}

	// footer-outro
	.footer-outro {
		margin-bottom: 8vh;

		.container-holder {
			max-width: 690px;
		}

		// footer-link
		.footer-link {
			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					margin: 10px 10px 0 0;

					a {
						position: relative;
						display: flex;
						align-items: center;
						@extend .btn;
						@extend .btn-primary;

						&::before {
							margin: -0.45rem 0.75rem -0.45rem 0;
							font-size: 24px;
							font-weight: 300;
							font-family: $font-awesome;
						}

						&[href^="tel:"]::before {
							content: "\f3cd";
						}

						&[href^="mailto:"]::before {
							content: "\f0e0";
						}
					}
				}
			}
		}
	}

	// footer-sitelinks
	.footer-sitelinks {
		margin: 10vh 0;

		.container-holder {
			margin-bottom: -45px;

			.column {
				margin-bottom: 45px;
			}
		}

		// footer-text
		.footer-text {
			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					&:not(:first-of-type) {
						margin-top: 5px;
					}

					a {
						color: $cream;
						text-decoration: underline;

						&:hover {
							color: $white;
						}
					}
				}
			}
		}

		// footer-logolink
		.footer-logolink {
			ul {
				li {
					margin-right: 20px;
					font-size: 24px;

					a {
						color: $cream;

						&:hover {
							color: $white;
						}
					}
				}
			}
		}
	}

	// footer-logo
	.footer-logo {
		margin-bottom: 3vh;
	}

	// footer-copyright
	.footer-copyright {
		padding: 3vh 0;
		background: $white;
		color: $gray-dark;
		font-weight: 300;
		text-align: center;

		// footer-text
		.footer-text {
			p {
				margin-bottom: 0;
			}

			a {
				color: $gray-dark;
				text-decoration: underline;

				&:hover {
					color: $green;
				}
			}
		}
	}
}
