// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 75vh;
	min-height: 300px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: calc(100vh - 210px);
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	// vimeo-video
	.vimeo-video {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				/* height = 100 * (9 / 16) = 56.25 */
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				/* width = 100 / (9 / 16) = 177.777777 */
				width: 177.78vh;
			}
		}
	}

	// wysiwyg
	.wysiwyg {
		z-index: 100;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 15px;
		color: $white;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
			text-align: center;
		}
	}

	// owl-carousel
	.owl-carousel {
		.item {

			&::after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 50%;
				background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);
			}

			.owl-caption-holder {
				z-index: 100;

				.owl-container {
					max-width: 960px;

					.owl-caption {
						display: flex;
						flex-direction: column;
						align-items: center;
						text-shadow: none;

						.owl-title {
							margin-bottom: 0;
							@include font-size($h1-font-size);

							@include media-breakpoint-up(xl) {
								font-size: 36px;
							}
						}

						.owl-subtitle {
							order: -1;
							@extend .h4;
							color: $white;
						}
					}
				}
			}
		}
	}

	// owl-dots
	.owl-nav,
	.owl-dots {
		display: none;
	}
}
