// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $green-dark;
	box-shadow: $shadow;
	transition: 0.5s;

	@include media-breakpoint-up(xl) {
		padding: 0 15px;
	}

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			margin-right: 15px;
			padding: 0;
			border: none;
			color: $white;
			font-size: 28px;
			transition: color 0.5s;

			i {
				width: 30px;
				font-size: 28px;
				font-weight: 400;
				line-height: 40px;
				text-align: center;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 200px;
		margin: 15px auto 15px 0;

		@include media-breakpoint-up(lg) {
			width: 250px;
			margin-top: 20px;
			margin-bottom: 20px;
		}

		@include media-breakpoint-up(xl) {
			width: 300px;
			margin-top: 30px;
			margin-bottom: 30px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;
			padding-bottom: 15px;

			.navbar-nav {
				>.nav-item {
					&:not(:last-of-type) {
						border-bottom: 1px solid rgba($white, 0.5);
					}

					>.nav-link {
						display: flex;
						align-items: center;
						color: $white;
						font-size: 16px;
						font-weight: 700;

						&:hover {
							color: $cream;
						}
					}

					&.active {
						>.nav-link {
							color: $cream;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 16px;

							.nav-link {
								color: $white;

								&:hover {
									color: $cream;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $cream;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin-left: auto;

				>.nav-item {
					margin-left: 10px;
					font-size: 16px;
					font-weight: 700;

					>.nav-link {
						color: $white;

						&:hover {
							color: $cream;
						}
					}

					&.active {
						>.nav-link {
							color: $cream;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;
						border-radius: 4px;

						.nav-item {
							font-size: 15px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $black;

								&:hover {
									color: $green;
								}
							}

							&.active {
								.nav-link {
									color: $green;
								}
							}
						}
					}
				}
			}
		}
	}

	// book-button
	div.book-button {
		margin-left: 20px;

		@include media-breakpoint-down(xs) {
			position: absolute;
			top: 100%;
			right: 15px;
		}

		ul {
			li {
				a {
					@extend .btn;
					background-color: $cream;
					border-color: $cream;
					color: $gray-dark;

					@include media-breakpoint-down(xs) {
						border-radius: 0 0 5px 5px !important;
					}

					&:hover {
						background-color: $green;
						border-color: $green;
						color: $white;
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 20px;

		.navbar-nav {
			.nav-item {
				.nav-link {
					border: none;
					color: $white;
					text-transform: uppercase;

					&::after {
						color: $cream;
					}

					&:hover {
						box-shadow: none !important;
					}
				}

				.dropdown-menu {
					border: none;
					box-shadow: $box-shadow;
					overflow: hidden;

					.dropdown-item {
						font-size: 15px;

						&:hover {
							background-color: $green;
							color: $white;
						}
					}
				}
			}
		}
	}
}

&.faq-overview {
	.header {
		position: relative;
	}
}
