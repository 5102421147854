// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 8vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-detail-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-8vh + 30px);
}

// lead-section
// =========================================================================
.lead-section {
	margin: 8vh 0;
	font-size: 20px;
	font-weight: 400;

	.container-one-column {
		.container-holder {
			max-width: 820px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// icons-collection
	.icons-collection {
		margin-top: 30px;
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// subtitle
		.subtitle {
			h5 {
				@extend .h4;
			}
		}

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 8vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 8vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}

		&.large-collection {
			.grid-items {
				.item {
					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	position: relative;

	// image-collection
	.image-collection {
		&.owl-carousel {
			.owl-stage-outer {
				padding-bottom: 12px;
			}

			.item {
				flex-direction: column;

				picture {
					height: 500px;

					@include media-breakpoint-up(xl) {
						height: 680px;
					}
				}

				.owl-caption-holder {
					position: static;

					.owl-container {
						margin-top: -50px;

						.owl-caption {
							position: relative;
							display: flex;
							flex-wrap: wrap;
							align-items: center;
							flex-direction: column;
							padding: 30px 15px;
							background: $green-dark;
							border-radius: 8px;
							box-shadow: 6px 6px 12px rgba($black, 0.15);

							@include media-breakpoint-up(md) {
								padding: 45px;
							}

							@include media-breakpoint-up(xl) {
								align-items: flex-end;
								padding: 60px;
								text-align: left;
							}

							.owl-title {
								@extend .h1;
								color: $cream;

								@include media-breakpoint-up(xl) {
									position: absolute;
									top: 60px;
									right: 50%;
									max-width: 400px;
									padding-right: 30px;
									text-align: right;
								}
							}

							.owl-subtitle {
								@extend .h4;
								color: $white;
							}

							.owl-description {
								margin-bottom: 0;
							}

							.owl-subtitle,
							.owl-description,
							.owl-btn {
								@include media-breakpoint-up(xl) {
									width: 50%;
								}
							}
						}
					}
				}
			}

			// owl-nav
			.owl-nav {
				display: none;
			}

			// owl-dots
			.owl-dots {
				top: 420px;
				bottom: auto;

				@include media-breakpoint-up(xl) {
					top: 600px;
				}

				.owl-dot {
					span {
						width: 50px;
						height: 8px;
						margin: 5px;
						background: $white;
						border: none;
						border-radius: 4px;
						box-shadow: none;
					}

					&:hover,
					&.active {
						span {
							background: $cream;
						}
					}
				}
			}
		}
	}
}

// featured-section
// =========================================================================
.featured-section {
	margin: 8vh 0;
	padding: 8vh 0;
	background: $green-dark;
	color: $white;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $cream;
		line-height: 1.35;
	}

	.container-two-columns {
		.container-holder {
			.column {
				&.one {
					padding-right: 15px !important;

					@include media-breakpoint-up(xl) {
						padding-right: 20px !important;
					}

					@include media-breakpoint-down(md) {
						br {
							display: none;
						}
					}

					@include media-breakpoint-up(lg) {
						text-align: right;
					}
				}

				&.two {
					padding-left: 15px !important;

					@include media-breakpoint-up(xl) {
						padding-left: 20px !important;
					}
				}
			}
		}
	}
}

// experience-section
// =========================================================================
.experience-section {
	margin: 8vh 0;

	// collection
	.collection {
		margin-top: 30px;

		.grid-items {
			.item {
				&:nth-child(-n+2) {
					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}
				}
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 8vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 8vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-8vh + 30px);
	margin-bottom: calc(-8vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 8vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 8vh 0;
}
